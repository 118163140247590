/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// scss-docs-start import-stack
// Configuration
// DO NOT REMOVE OR ALTER //
@import "functions";

// Add your custom SASS variables here, you also overwrite the Bootstrap variables here
// ------------------------------------------------------------------------------------
$imagePath: "../../assets/";
$primary: #0083c2;
$success: #4fc76f;
$secondary: #425563;
$blue: #003e6a;
$info: #aabcc9;
$navy: #075072;

$font-family-sans-serif: Calibri, "Roboto", Arial, sans-serif;
$headings-font-family: "Roboto", sans-serif;
$font-weight-bolder: 700;
$headings-font-weight: 400;

$border-radius: 10px;

$font-size-base: 1rem;

$h1-font-size: $font-size-base * 4.375;
$h2-font-size: $font-size-base * 2.8125;
$h3-font-size: $font-size-base * 2.25;
$h4-font-size: $font-size-base * 1.875;
$h5-font-size: $font-size-base * 1.5;
$h6-font-size: $font-size-base;

$carousel-indicator-active-bg: $primary;

// ------------------------------------------------------------------------------------

// DO NOT REMOVE OR ALTER //
@import "variables";
@import "mixins";
@import "utilities";

// Updating utilities here
// ------------------------------
$utilities: map-merge(
  $utilities,
  (
    "width":
      map-merge(
        map-get($utilities, "width"),
        (
          values:
            map-merge(
              map-get(map-get($utilities, "width"), "values"),
              (
                20: 20%,
              )
            ),
        )
      ),
  )
);

// Layout & components
@import "root";
@import "reboot";
@import "type";
@import "images";
@import "containers";
@import "grid";
@import "tables";
@import "forms";
@import "buttons";
@import "transitions";
@import "dropdown";
@import "button-group";
@import "nav";
@import "navbar";
@import "card";
@import "accordion";
@import "breadcrumb";
@import "pagination";
@import "badge";
@import "alert";
@import "progress";
@import "list-group";
@import "close";
@import "toasts";
@import "modal";
@import "tooltip";
@import "popover";
@import "carousel";
@import "spinners";
@import "offcanvas";
@import "placeholders";

// Helpers
@import "helpers";

// Utilities
@import "utilities/api";
// scss-docs-end import-stack

// Add your custom CSS / SCSS here
// For loading in images in CSS:
// background-image: url("#{$imagePath}imageName.jpg");

// Bootstrap Icons
// @import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

// navbar
nav.navbar {
  background-color: #000f24;
  font-size: 14px;
  text-transform: uppercase;
}

.nav-item {
  margin: 0 10px;
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #4fd6db;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.navbar-dark .navbar-toggler {
  border: none;
}

.navbar {
  .dropdown-menu {
    background-color: #000f24;
    margin-left: -10px;
    padding-bottom: 0;
    a {
      color: white;
      font-size: 14px;
      padding: 0.75rem 1rem;
      &:hover {
        color: #000f24;
      }
    }

    li:last-of-type a {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}

// text colours -------------------------

body {
  color: #425563;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.25;
}

.border-dark {
  border-color: #adb5bd !important;
}

.blue {
  color: #0083c2;
}

.accentBlue {
  color: #4fd6db;
}

a.accentBlue {
  color: #4fd6db;

  &:hover,
  &:focus {
    color: lighten(#4fd6db, 20%);
  }
}

.text-darker-blue {
  color: $blue;
}

.text-navy {
  color: $navy;
}

.dark-blue {
  color: #001131;
}

.light-grey {
  color: #dfdfdf;
}

.dark-grey {
  color: #425563;
}

.gold {
  color: #c9b362;
}

.platinum {
  color: $info;
}

.silver {
  color: #93979a;
}

//-----------------------------------------

// background colours ---------------------

.bg-blue {
  background-color: #0083c2;
}

.bg-dark-blue {
  background-color: #001131;
}

.bg-dark-blue2 {
  background-color: #000f24;
}

.bg-dark-blue3 {
  background-color: $blue;
}

.bg-dark-grey {
  background-color: #425563;
}

.bg-light-grey {
  background-color: #dfdfdf;
}

.bg-navy {
  background-color: $navy;
}

// Text Balance Utility ------------------
.text-balance {
  text-wrap: balance;
}

// ---------------------------------------

// button Styling ------------------------

.btn-primary {
  color: #ffffff;
  font-weight: bold;
  background-color: #0083c2;
  border: none;
  border-radius: 50px;
  padding: 10px 25px;
  text-transform: uppercase;
  > .text-reset {
    text-transform: initial;
  }
  &.bg-dark-grey {
    background-color: #425563;
    &:hover,
    &:focus {
      background-color: #0083c2;
      color: #ffffff;
    }
  }
  &.rounded-3 {
    border-radius: $border-radius !important;
  }
}

.btn-primary:hover,
.btn-primary:active {
  color: #ffffff;
  background-color: #425563;
}

.btn-primary:focus {
  color: #ffffff;
}

.btn-secondary {
  border: none;
  border-radius: 50px;
  padding: 10px 25px;
  text-transform: uppercase;
  font-weight: 700;
}

// Additional Responsive Styles
.overflow-lg-hidden {
  @include media-breakpoint-up(lg) {
    overflow: hidden !important;
  }
}
.h-xl-auto {
  @include media-breakpoint-up(xl) {
    height: auto !important;
  }
}
//

// ---------------------------------------

// forms ---------------------------------

.form-control {
  color: #425563;
  background-color: #fff;
  border: 2px solid #425563;
  border-radius: 50em;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &.blue {
    color: $blue;
    border: 2px solid $blue;
    + label {
      color: $blue;
    }
  }
}

textarea.form-control {
  border-radius: 30px;
}

select.form-select {
  color: #425563;
  border: 2px solid #425563;
  border-radius: 50em;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

// ---------------------------------------

// Cards ---------------------------------
.portraitCardWrapper {
  position: relative;
  overflow: hidden;
  border-radius: $border-radius;
}

.portraitCardContainer {
  position: relative;
}

.portraitCard {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 15px;
  @include media-breakpoint-down(sm) {
    padding: 0 10px;
  }
}

.portraitCard p {
  font-size: 14px;
  line-height: 1rem;
}

.speakerBioContainer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 15px;
  overflow: hidden;
  background-color: #001131;
  opacity: 0;
  transition: 300ms;
  color: #ffffff;
  font-size: 14px;

  @include media-breakpoint-only(xxl) {
    padding: 15px 10px;
  }
}

.speakerBioContainer:hover {
  opacity: 1;
  transition: 300ms;
  cursor: pointer;
}

.speaker-photo {
  width: 240px;
  height: auto;
  @include media-breakpoint-down(lg) {
    width: 100%;
  }
}

.student-photo {
  width: 100%;
  max-width: 180px;
  height: auto;
  border-radius: $border-radius;
}

.studentQuotesBanner {
  background-image: url("#{$imagePath}about/studentQuoteBG.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.student-quotes {
  min-height: 432px;
  @include media-breakpoint-only(xl) {
    min-height: 390px;
  }
  @include media-breakpoint-only(lg) {
    min-height: 346px;
  }
  @include media-breakpoint-only(md) {
    min-height: 400px;
  }
  @include media-breakpoint-only(sm) {
    min-height: 515px;
  }
  @include media-breakpoint-only(xs) {
    min-height: 850px;
  }
}

// ---------------------------------------

// home page -----------------------------

.homeHeroBanner {
  position: relative;
  background-image: url("#{$imagePath}home/2024/heroBanner_2024.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center right;
  @include media-breakpoint-down(xl) {
    background-position: center center;
  }
  @include media-breakpoint-down(lg) {
    background-position: center right 40%;
  }
  h1 {
    line-height: 1;
    @include font-size(3.75rem);

    @include media-breakpoint-down(md) {
      line-height: 1.2;
    }
  }
  @include media-breakpoint-down(md) {
    background-image: url("#{$imagePath}home/2024/heroBanner_2024_767.jpg");
    background-size: contain;
    background-position: top center;
    background-color: #000f24;
  }
  @include media-breakpoint-down(sm) {
    background-image: url("#{$imagePath}home/2024/heroBanner_2024_576.jpg");
    background-size: contain;
    background-position: top center;
    background-color: #000f24;
  }

  .min-vh-100 {
    min-height: calc(100vh - 56px) !important;
  }
}

.homeHeroBannerGradient {
  background: linear-gradient(
    90deg,
    rgba(0, 15, 36, 1) 0%,
    rgba(1, 11, 27, 0.79) 37%,
    rgba(0, 16, 39, 0) 55%
  );
  position: relative;
  z-index: 1;
  height: 100%;
  @include media-breakpoint-only(xl) {
    background: linear-gradient(
      90deg,
      rgba(0, 15, 36, 1) 0%,
      rgba(1, 11, 27, 0.79) 40%,
      rgba(0, 16, 39, 0) 70%
    );
  }
  @include media-breakpoint-down(lg) {
    background: linear-gradient(
      90deg,
      rgba(0, 15, 36, 1) 0%,
      rgba(1, 11, 27, 0.79) 45%,
      rgba(0, 16, 39, 0) 100%
    );
  }
  @include media-breakpoint-down(md) {
    background: linear-gradient(
        0deg,
        rgba(0, 15, 36, 1) 0%,
        rgba(1, 11, 27, 0.79) 40%,
        rgba(0, 16, 39, 0) 60%
      )
      bottom center;
    padding-top: 500px !important;
  }
  @include media-breakpoint-down(sm) {
    background: linear-gradient(
        0deg,
        rgba(0, 15, 36, 1) 0%,
        rgba(1, 11, 27, 0.79) 45%,
        rgba(0, 16, 39, 0) 70%
      )
      bottom center;
    padding-top: 300px !important;
  }
}

@media (max-width: 350px) {
  .homeHeroBannerGradient {
    padding-top: 200px !important;
  }
}

.particle-container {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  #particles-js {
    height: 100%;
  }
}

#canvas {
  display: block;
  width: 100%;
  height: 100vh;
}

.homeHeaderGradient {
  position: relative;
  z-index: 1;
  background: linear-gradient(
    180deg,
    rgba(0, 15, 36, 1) 2%,
    rgba(59, 106, 124, 1) 10%,
    rgba(59, 106, 124, 0) 35%
  );
  @include media-breakpoint-down(lg) {
    background: linear-gradient(
      180deg,
      rgba(0, 15, 36, 1) 2%,
      rgba(59, 106, 124, 1) 35%,
      rgba(59, 106, 124, 0) 45%
    );
  }
  @include media-breakpoint-down(sm) {
    background: linear-gradient(
      180deg,
      rgba(0, 15, 36, 1) 2%,
      rgba(59, 106, 124, 1) 30%,
      rgba(59, 106, 124, 0) 50%
    );
  }

  &.withoutSponsors {
    background: linear-gradient(
      180deg,
      rgba(0, 15, 36, 1) 5%,
      rgba(59, 106, 124, 1) 85%,
      rgba(59, 106, 124, 0) 110%
    );
  }

  .border-start.border-dark {
    @include media-breakpoint-down(lg) {
      border: 0 !important;
    }
  }
}

.theme-title {
  max-width: 780px;

  @include media-breakpoint-down(xl) {
    max-width: 800px;
  }

  @include media-breakpoint-down(lg) {
    max-width: 820px;
  }

  @include media-breakpoint-down(md) {
    max-width: none;
  }
}

.white-bar {
  height: 3px;
  width: 100%;
  flex: 1 1 auto;
  background-color: #ffffff;
}

.counter h1 {
  -webkit-text-stroke: 1px #4fd6db;
  color: transparent;
  font-weight: bold;
  @include font-size(4rem);
  margin: 0;
}

.counter p {
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  @include font-size(1rem);
}

.unprecedentedValue {
  background-image: url("#{$imagePath}home/PixelatedBG.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.homeVenueBanner {
  background-image: url("#{$imagePath}home/2024/homeVenueBG.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  padding-top: 80px;
  background-position: bottom center;
  @include media-breakpoint-down(md) {
    padding-top: 24px;
    background-size: cover;
  }
  // p,
  // h3 {
  //   text-shadow: 0 1px 2px rgba($black, 0.325);
  // }
}

@media (max-height: 690px) {
  .homeVenueBanner {
    height: 125vh;
  }
}
@media (max-height: 550px) {
  .homeVenueBanner {
    height: 150vh;
  }
}

@media (max-height: 450px) {
  .homeVenueBanner {
    height: 175vh;
  }
}

.homeVenueBannerGradient {
  background: linear-gradient(
    180deg,
    rgba(#f3ceb1, 1) 0%,
    rgba(#e47c55, 1) 100%
  );
}

// ----
$mouse_color: #4fd6db;
$mouse_width: 25px;
$mouse_height: 40px;
$border_width: 2px;

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }
  @-moz-keyframes #{$animationName} {
    @content;
  }
  @-o-keyframes #{$animationName} {
    @content;
  }
  @keyframes #{$animationName} {
    @content;
  }
}

@mixin animation($animations) {
  -webkit-animation: $animations;
  -moz-animation: $animations;
  -o-animation: $animations;
  animation: $animations;
}

$wheel_size: $border_width * 3;

@include keyframes(mouse-scroll) {
  0% {
    top: 10%;
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    top: 30%;
    opacity: 0;
  }
}

.scroll-msg-inner {
  width: $mouse_width;
  height: $mouse_height;
  position: relative;
  border-radius: $mouse_width/2 + $border_width * 2;
  border: $border_width solid $mouse_color;
  margin: 0 auto;
}
.scroll-msg-wheel {
  position: absolute;
  top: 10%;
  left: 50%;
  width: $wheel_size;
  height: $wheel_size;
  margin-left: -$wheel_size/2;
  border-radius: 50%;
  background-color: $mouse_color;

  @include animation(mouse-scroll 0.9s infinite);
}

// ---------------------------------------

// About Satnac --------------------------
.aboutHeroBanner {
  background-image: url("#{$imagePath}about/aboutSatnacBanner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left center;
  @include media-breakpoint-down(md) {
    background-image: url("#{$imagePath}about/aboutSatnacBanner_767.jpg");
    background-position: center center;
  }
  @include media-breakpoint-down(sm) {
    background-image: url("#{$imagePath}about/aboutSatnacBanner_576.jpg");
  }
}

.aboutUsObjectivesIcons {
  font-size: 45px;
  margin-right: 15px;
}

.carousel-bg {
  background-image: url("#{$imagePath}about/carousel-bg.jpg");
  background-repeat: repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23001131' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23001131' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.carousel-control-prev {
  width: auto;
}

.carousel-control-next {
  width: auto;
}

// ---------------------------------------

// Registration --------------------------
.registrationHeroBanner {
  background-image: var(--background-image);
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #003E6A;
  @include media-breakpoint-down(md) {
    background-position: top center;
  }
}

.registrationHeroBannerGradient {
  background: linear-gradient(
    120deg,
    rgba(#003E6A, 1) 35%,
    rgba(#003E6A, 0) 70%
  );
  @include media-breakpoint-down(md) {
    background: linear-gradient(
      0deg,
      rgba(#003E6A, 1) 30%,
      rgba(#003E6A, 0) 100%
    );
  }
}

.venueInfoBanner {
  background-image: 
    linear-gradient(to bottom, rgba(#000F24, 0) 25%, rgba(#000F24, 1) 100%),
    url("#{$imagePath}photos/2024/kruger-national-park-bridge-over-river.webp");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  padding-top: 400px;
  @include media-breakpoint-down(lg) {
    padding-top: 240px;
    background-image: 
      linear-gradient(to bottom, rgba(#000F24, .25) 0%, rgba(#000F24, 1) 100%),
      url("#{$imagePath}photos/2024/kruger-national-park-bridge-over-river.webp");
  }
}

.bannerSocial_btns {
  color: #ffffff;
  font-size: $h3-font-size;
  &:hover,
  &:focus {
    color: #4fd6db;
  }
}

// ---------------------------------------

// ---------------------------------------

// programme -----------------------
.programeBackground {
  background-image: url("#{$imagePath}programme/ProgrammeBG.svg");
  background-repeat: no-repeat;
  background-size: cover;
  @include media-breakpoint-down(xl) {
    background: none;
  }
}

// call for papers -----------------------
.papersHeroBanner {
  background-image: url("#{$imagePath}call-for-papers/heroBanner_cfp.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.papersNotesBanner {
  background-image: url("#{$imagePath}call-for-papers/authorNotes.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  @include media-breakpoint-down(lg) {
    background-position: left center;
  }
}

.papersSubmissionBanner {
  background-image: url("#{$imagePath}call-for-papers/paperSubmission.jpg");
  background-color: rgb(110, 160, 184);
  background-color: linear-gradient(
    0deg,
    rgba(110, 160, 184, 1) 0%,
    rgba(98, 155, 181, 1) 100%
  );
  background-repeat: no-repeat;
  background-position: left center;
  @include media-breakpoint-down(lg) {
    background-image: none;
    background: rgb(110, 160, 184);
    background: linear-gradient(
      0deg,
      rgba(110, 160, 184, 1) 0%,
      rgba(98, 155, 181, 1) 100%
    );
  }
}

.accordion-item {
  background-color: #fff;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);

  &:last-of-type {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.accordion-body {
  padding-top: 30px;
  padding-bottom: 30px;
}

.accordion-button {
  padding-left: 0;
  padding-right: 0;

  &:focus {
    z-index: 3;
    border-color: transparent;
    outline: 0;
    box-shadow: none;
  }
  &:not(.collapsed) {
    color: #0076af;
    background-color: transparent;
  }
}
// ---------------------------------------

// Sponsorship ---------------------------
a .sponsorCard p {
  color: #425563;
}

.diamond-pattern-bg {
  background-image: url("#{$imagePath}sponsorship/sponsorship_pattern_bg.png"),
    linear-gradient(
      180deg,
      rgba(248, 248, 248, 1) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  background-repeat: space;  
}

.small-diamond-pattern-bg {
  background-image: url("#{$imagePath}challenge/sponsorship_pattern_bg_50.png");
  background-repeat: repeat;
  background-size: 1200px;
}

.sponsorCard {
  background-color: $white;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
  padding: 20px 30px;
  height: 100% !important;
  border-radius: $border-radius;
  p {
    margin: 0;
  }
}

.img-left {
  position: absolute;
  left: 0;
  top: 0;
  width: auto;
  height: 100%;
  @include media-breakpoint-down(xxl) {
    left: 50%;
    transform: translateX(-50%);
  }
}

// ---------------------------------------

// Contact -------------------------------
.contactHeroBanner {
  height: 440px;
  background-image: url("#{$imagePath}contact-us/heroBanner_contact.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  @include media-breakpoint-down(lg) {
    height: 240px;
  }
  @include media-breakpoint-down(sm) {
    height: 200px;
    background-position: center center;
  }
}

// ---------------------------------------

.programmeBanner {
  background-image: url("#{$imagePath}programme/satnac_programme_banner.webp");
  background-image: image-set(
    "#{$imagePath}programme/satnac_programme_banner.webp" 1x,
    "#{$imagePath}programme/satnac_programme_banner@2x.webp" 2x
  );
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  @include media-breakpoint-down(md) {
    background-image: linear-gradient(to bottom, rgba(0, 15, 36, 0) 0%, rgba(0, 15, 36, 0.75) 100%), url("#{$imagePath}programme/satnac_programme_banner.webp");
    background-image: linear-gradient(to bottom, rgba(0, 15, 36, 0) 0%, rgba(0, 15, 36, 0.75) 100%), image-set(
      "#{$imagePath}programme/satnac_programme_banner.webp" 1x,
      "#{$imagePath}programme/satnac_programme_banner@2x.webp" 2x
    );
  }
}

// Proceedings
.proceedingsBanner {
  background-image: url("#{$imagePath}proceedings/proceedings-banner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  @include media-breakpoint-down(md) {
    background-position: -180px top;
  }
}

.proceedingsBG {
  background: url("#{$imagePath}proceedings/proceedings-bg.png") repeat top
    center;
}

// Challenge
.challengeBanner {
  background-image: url("#{$imagePath}challenge/challenge-banner-2.webp");
  background-repeat: no-repeat;
  background-size: cover;
  @include media-breakpoint-down(lg) {
    background-size: auto;
    background-position: center center;
  }
}

.pastChallengeBanner {
  background-image: url("#{$imagePath}challenge/challenge-banner-3.webp");
  background-repeat: no-repeat;
  background-size: cover;
  @include media-breakpoint-down(lg) {
    background-size: auto;
    background-position: center center;
  }
}

.line-pattern-bg {
  background-image: url("#{$imagePath}challenge/line-pattern-bg.webp");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.calendar-background {
  background-image: url("#{$imagePath}challenge/challenge-calendar-bg.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% auto;
  @include media-breakpoint-down(xxl) {
    background-size: cover;
  }
  @include media-breakpoint-down(lg) {
    background-size: cover !important;
    background-attachment: scroll !important;
  }
}

.participation-background {
  background-image: url("#{$imagePath}challenge/challenge-participation-bg-2.webp");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% auto;
  @include media-breakpoint-down(xxl) {
    background-size: cover;
  }
  @include media-breakpoint-down(lg) {
    background-size: auto !important;
    background-position: center right;
    background-attachment: scroll !important;
  }
}

.nav-tabs {
  .nav-link {
    border: 0;
    border-bottom: 4px solid transparent;
    color: #707070;
    padding: 1rem;
    &.active {
      color: #0083c2;
      border-color: #0083c2;
    }
  }
}

// Footer
.footerSocialIcons {
  font-size: 1.5rem;
}

.footerSocialIcons:hover {
  color: #0083c2;
}

.hostedByTelkom {
  background-image: url("#{$imagePath}home/hostedByTelkom_logo.png");
  background-repeat: no-repeat;
}

// Borders (Responsive)
.border-sm {
  @include media-breakpoint-down(sm) {
    border: 0 !important;
  }
}

// Asterisk  warning
.asterisk-warning {
  position: relative;
  &::after {
    content: "*";
    position: absolute;
    top: calc(50% - 5px);
    transform: translateY(-50%);
    font-size: 2.5rem;
    color: #12b5e6;
  }
}

// Parallax background
.parallax-bg {
  background-attachment: fixed;
  background-size: 100% auto;
}

// Background with images
.student-accommodation-background {
  background-image: url("#{$imagePath}register/studentAccommodationBG.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% auto;
  @include media-breakpoint-down(xxl) {
    background-size: cover;
  }
  @include media-breakpoint-down(sm) {
    background-image: url("#{$imagePath}register/studentAccommodationBG_mobile.jpg");
    background-attachment: scroll;
  }
}

@media all and (device-width: 1024px) {
  // Registration background image fix for Ipad
  .parallax-bg {
    background-attachment: scroll;
  }
}

/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  // Registration background image fix for iPhone 6, 6S, 7 and 8
  .parallax-bg {
    background-attachment: scroll;
  }
}
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  // Registration background image fix for iPhone 6+, 7+ and 8+
  .parallax-bg {
    background-attachment: scroll;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  // Registration background image fix for iPhone X
  .parallax-bg {
    background-attachment: scroll;
  }
}

.location-pin-background {
  background-image: url("#{$imagePath}register/location-pin-lg.svg");
  background-repeat: no-repeat;
  background-position: right 50px center;
  @include media-breakpoint-down(xl) {
    background: none;
  }
}

// ---------------------------------------

// Quote Background -------------------------------
.quotesBackgroundStars {
  background-image: url("#{$imagePath}about/quoteBGStars.jpg");
  background-repeat: no-repeat;
  min-height: 260px;
  @include media-breakpoint-only(lg) {
    min-height: 280px;
  }
  @include media-breakpoint-only(md) {
    min-height: 300px;
  }
  @include media-breakpoint-only(sm) {
    min-height: 320px;
  }
  @include media-breakpoint-only(xs) {
    min-height: 500px;
  }
}

// ---------------------------------------
// UL custom styles -------------------------------

ul.custom-list {
  list-style: none; /* Remove default bullets */
  padding: 0 0 0 1.5rem;
  li {
    margin-bottom: 0.5rem;
  }
  li::before {
    content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: $primary; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1.25em; /* Also needed for space (tweak if needed) */
    margin-left: -1.25em; /* Also needed for space (tweak if needed) */
  }
  &.dark {
    li::before {
      color: #465462; /* Change the color */
    }
  }
}

ul.tick-list {
  list-style: none; /* Remove default bullets */
  padding: 0 0 0 1rem;
  li {
    margin-bottom: 1.25rem;
    padding-left: 1rem;
  }
  li::before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16.578' height='11.887' viewBox='0 0 16.578 11.887'%3E%3Cpath d='M450.407,195.556h0l-8.9,9.227h0a.328.328,0,0,1-.47.006l-4.943-4.944h0a.33.33,0,1,1,.466-.466h0l4.706,4.706,8.663-8.984h0a.328.328,0,0,1,.475.453Z' transform='translate(-434.919 -194)' fill='%23425563' stroke='%23454545' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' fill-rule='evenodd'/%3E%3C/svg%3E"); /* Add content: \2022 is the CSS Code/unicode for a bullet */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1rem; /* Also needed for space (tweak if needed) */
    margin-left: -2rem; /* Also needed for space (tweak if needed) */
    margin-right: 1rem;
  }
}

// Font sizes
.fs-12px {
  font-size: 12px;
}

.fs-14px {
  font-size: 14px;
}

.fs-lg {
  font-size: 1.25rem;
}

// Media queries --------------------------------------------

@media (max-width: 1200px) {
  .nav-item {
    margin: 0 6px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .nav-item {
    margin: 10px 0px;
  }
}

@media (max-width: 992px) {
  .navbar-nav {
    min-height: 100vh !important;
  }

  .nav-item {
    margin: 15px 0px;
  }
}

// Form validation
.invalid-feedback {
  color: #d1331a;
}

.alert-success {
  background-color: #4fc76f;
  color: #ffffff;
  border: 0;
  border-radius: 20px;
}

.alert-danger {
  background-color: #dc3545;
  color: #ffffff;
  border: 0;
  border-radius: 20px;
}

// ----------------------------------------------------------

// Accordion custom styles for dark version
.accordion-dark {
  .accordion-item {
    margin-bottom: 1.25rem;
    border: 0;
    .accordion-header {
      background-color: $secondary;
    }
    .accordion-button {
      color: white;
      background-color: $secondary;
      font-size: $h5-font-size;
      &::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' stroke='white' stroke-width='0.5' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      }
    }
    .accordion-body {
      box-shadow: 0 10px 10px rgba(black, 0.16);
    }
  }
}
